<template>
<div>
  <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
  <CModalExtended
    :title="$t('label.nuevo')+' '+$t('label.stowageRole')"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
    class="modal-extended"
  >
    <form @submit.stop.prevent="submit" class="company-form">
      <transition name="slide-fade" mode="out-in">
        <CRow v-if="!showCollapseVesselTtype">
          <CCol sm="12">
            <CCard class="card-simple">
              <CCardBody>
                <CRow>
                  <CCol sm="2" class="text-right mt-2 pr-1">
                    <label for="tpGrua"><b class="text-danger"> * </b> {{ $t('label.role') }} </label>
                  </CCol>
                  <CCol sm="6" class="pr-0">
                    <v-select 
                      :options="rolesOptions"
                      label="StowageRoleName"
                      :placeholder="$t('label.select')"
                      :class="isRoleValido(roleVal)" 
                      v-model="role"
                      :disabled="isRolesEmpty"
                    />
                  </CCol>
                  <CCol sm="4" class="px-0">
                    <CButton
                      @click="showCollapseRole = !showCollapseRole"
                      size="sm"
                      :color="checkColorCollapse"
                      v-c-tooltip="{
                        content: $t('label.add')+$t('label.role'),
                        placement: 'top-end'
                      }"
                     
                    >
                      <CIcon :name="checkIconCollapse"/>
                    </CButton>
                    <CButton
                      color="add"
                      size="sm"
                      class="ml-4"
                      @click.prevent="newVesselType"
                      v-c-tooltip="{
                        content: $t('label.vesselType'),
                        placement: 'top-end'
                      }"
                    >
                      <CIcon name="cil-playlist-add"/><span class="ml-1">
                        {{$t('label.nuevo') }}
                      </span>
                    </CButton>
                  </CCol>

                  <CCol sm="2" class="text-right mt-2 pr-1">
                  </CCol>
                  <CCol sm="6">
                    <div :class="isRoleValid">
                            {{$t('label.required')}}
                          </div>
                  </CCol>
                  <CCol sm="4"> 
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm="12"> 
            <CCollapse :show="showCollapseRole">
              <role-collapse
                :Edit="roleEdit"
                :stowage-role="role"
                @close="handleClose"
                :LimpiarCollapse.sync="LimpiarCollapse"
              />
            </CCollapse>
          </CCol>
          <CCol sm="12">
            <dataTableExtended
              class="align-center-row-datatable"
              size="sm"
              :fields="fields"
              :items="tpBuquesOptions"
              :items-per-page="5"
              column-filter
              pagination
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :noItemsView="tableText.noItemsViewText"
              :loading="isLoading"
               
            >
            <template #loading>
              <loading/>
            </template>
              <template #checked="{ item }">
                <td class="center-cell">
                  <CInputCheckbox
                    custom
                    class="checkbook-filter"
                    :checked="item.initialActive"
                    @update:checked="handleTpBuque($event, item)"
                  />
                </td>
              </template>
              <template #checked-filter>
                <CInputCheckbox
                  :label="$t('label.all')"
                  class="checkbook-filter"
                  custom
                  @update:checked="selectAllTpVessel($event)"
                  :checked="selectAll"
                />
              </template>
              <template #Details="{item}">
                <td class="center-cell  py-2">
                    <CButton
                      color="edit"
                      size="sm"
                      v-c-tooltip="{
                        content: $t('label.edit')+$t('label.vesselType'),
                        placement: 'top-end'
                      }"
                      @click.stop="editVesselType(item)"
                    >
                      <CIcon name="pencil" />
                    </CButton>
                </td>
              </template>
            </dataTableExtended>
            <p v-if="itemsVesselTypeChecked.length == 0" class="mt-2 text-danger">
              * {{$t('label.must')+$t('label.toSelect')+$t('label.atLeast')}} 1 {{$t('label.vesselType')}}
            </p>
          </CCol>
        </CRow>
             
        <tp-buque-form
          v-else
          :tp-vessel-id="tpBuqueId"
          @submited="handleVesselTypeSubmit"
          @cancel="handleCancel"
        />
      </transition>       
    </form>
    <template #footer>
      <CButton
        v-if="!showCollapseVesselTtype"
        color="add"
        class="d-flex align-items-center"
        :disabled="isSubmit"
        @click.stop="submit"
      >
          <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
      <!--
        <div v-if="!isSubmit">
          <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
        </div>
        <div v-if="isSubmit">
          <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="ml-1">{{$t('button.accept')}}</span>
        </div>-->
      </CButton>
      <CButton
        color="wipe"
        :disabled="isSubmit"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
      </CButton>
    </template>
  </CModalExtended>
</div>
</template>

<script>
import RoleFormValidations from '@/_validations/estiba/RoleFormValidations';
import { tableTextTranslatedHelpers } from '@/_helpers/funciones';
import UpperCase  from '@/_validations/uppercase-directive';
import { RoleMixin, RoleSingleCollapseMixin } from '@/_mixins/estiba';
import  ModalMixin  from '@/_mixins/modal';

import TpBuqueForm from '@/pages/tipo-buque/tipo-buque-form';
import RoleCollapse from '@/pages/estiba/roles/role-collapse';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    isLoading: false,
    isLoadingRoles: false,
    role: null, 

    itemsVesselTypeList: [], 
    rolesList: [], 

    itemsVesselTypeChecked: [], 
    itemsVesselTypeCheckedUpdated: [], 
    itemsVesselTypeStowageRoleCheckedUpdated: [], 
    
    isEdit: false, 
    tpBuqueId: '',
    selectAll: false, 
    resetSelectAll: false, 
    Status: null, 
    LimpiarCollapse: false, 
   
    showCollapseVesselTtype: false, 
    showCollapseRole: false, 

    roleVal: false, 
    selectedTpVessel: null,
 
  }
}

function fields() {
  return [
    { key: 'Nro', label: '#', filter: false, _style: 'width:3%; text-align:center;' },
    { key: 'TpVesselName', label:this.$t('label.vesselType'), _style:'width:60%;', _classes:"text-left"},
    { key: 'checked', label: this.$t('agridTable.applyFilter'), _style: 'width:30%;', sorter: false, filter: false,_classes:"text-center" },
    { key: 'Details', label: '', _style: 'width:5%; min-width:45px;', sorter: false, filter: false, _classes:"text-center" }
  ];
}

function newVesselType() {
  this.tpBuqueId = '';
  this.showCollapseVesselTtype = true;
}

function editVesselType(item) {
  this.tpBuqueId = item.TpVesselId;
  this.showCollapseVesselTtype = true;
}

function handleVesselTypeSubmit() {
  this.showCollapseVesselTtype = false;
  this.getVesselTypeList();
}

function handleCancel() {
  this.showCollapseVesselTtype = false;
  this.tpBuqueId = '';
}


function getVesselTypeList() {
  this.isLoading = true;

  this.$http.get("TpVessel-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.itemsVesselTypeList = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}






//Methods
function submit() {
  try {
    this.isLoadingGeneral = true;
    this.isSubmit = true;
    this.roleVal = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.isLoadingGeneral = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }
    let StowageRoleVesselJson = this.itemsVesselTypeChecked.map((item) => Object.assign({},{
      StowageRoleId: this.role.StowageRoleId,
      TpVesselId: item.TpVesselId
    }));

    this.$http.post("StowageRoleVessel-insert", StowageRoleVesselJson, { root: 'StowageRoleVesselJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.isLoadingGeneral = false;
    });
  } catch (e) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
    });
  }
}

function toggle(newVal) {
  if(newVal)
  {
    this.resetForm();
    this.loadData(); 
  } 
  else
  {
    this.resetForm();
    this.$emit('submited');
  }

  this.modalActive = newVal;
}
function resetForm() {
  this.role = null;
  this.rolesList = [];
  this.selectedTpVessel = null
  this.itemsVesselTypeList = [];
  this.showCollapseRole = false;
  this.roleEdit = false;
  this.tpBuqueId = '';
  this.showCollapseVesselTtype = false;
  this.itemsVesselTypeChecked = [];
  this.selectAll = false;
  this.roleVal = false;
  this.limpiarCollapse = false;
  this.$v.$reset();
}
function handleTpBuque(event, item) {
  if (event === true) {
    let index = this.itemsVesselTypeChecked.findIndex((type) => type.TpVesselId == item.TpVesselId);
    if(index == -1){
      this.itemsVesselTypeChecked.push(item);
    }
  } else {
    let index = this.itemsVesselTypeChecked.findIndex((type) => type.TpVesselId == item.TpVesselId);
    if(index != -1)
      this.itemsVesselTypeChecked.splice(index, 1);
  }
}
function getRoles() {
  this.isLoadingRoles = true;

  this.$http.get("StowageRole-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.rolesList = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingRoles = false;
  });
}
function selectAllTpVessel(event) {
  if(event) {
    this.itemsVesselTypeList.forEach(element => {
      let index = this.itemsVesselTypeChecked.findIndex((type) => type.TpVesselId==element.TpVesselId);
      if(index == -1)
        this.itemsVesselTypeChecked.push(element);
    });
    this.selectAll = true;
  } else {
    this.itemsVesselTypeChecked.splice(0, this.itemsVesselTypeChecked.length);
    this.selectAll = false;
  }
}
function isRoleValido(roleVal) {
  if(this.roleVal === true){ 
    return (this.role == null || this.role == "") ? 'no-valido' : 'valido';
  }
}

//Computeds:
function tpBuquesOptions() {
  return this.itemsVesselTypeList.map((tpBuque) => {

    let index = this.itemsVesselTypeChecked.findIndex((type) => type.TpVesselId == tpBuque.TpVesselId);

    return Object.assign({}, tpBuque, {
      initialActive: index != -1 ? true : false,
      _classes: 'text-center align-middle'
    });
  });
}
function rolesOptions() {
  return this.rolesList.map((item) => Object.assign({}, item, {
    value: item.StowageRoleId,
    label: item.StowageRoleName
  }));
}
function isSubmitValid() {
  return this.$v.$invalid || this.isSubmit || this.itemsVesselTypeChecked.length == 0;
}
function tableText(){
  return tableTextTranslatedHelpers(this);
}


function checkIconCollapse() {
  return this.showCollapseRole ? 'cil-minus' : 'cil-plus';
}

function checkColorCollapse(){
  return this.showCollapseRole ? "wipe" : "add" ;
}
export default {
  name: 'modal-create-role-stowage',
  mixins: [
    RoleMixin,
    RoleSingleCollapseMixin,
    ModalMixin
  ],
  props: {
    modal: Boolean
  },
  components: {
    TpBuqueForm,
    RoleCollapse
  },
  data,
  validations: RoleFormValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    getVesselTypeList,
    handleTpBuque,
    newVesselType,
    handleVesselTypeSubmit,
    handleCancel,
    editVesselType,
    getRoles,
    selectAllTpVessel,
    isRoleValido,
  },
  computed: {
    tpBuquesOptions,
    rolesOptions,
    isSubmitValid,
    fields,
    tableText,
    checkIconCollapse,
    checkColorCollapse
  },
  watch:{
    showCollapseRole: function() {
      if (this.showCollapseRole){
        this.LimpiarCollapse=false;
      }else{
        this.LimpiarCollapse=true;
        this.getRoles();
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.center-cell-embalaje {
  text-align: center;
}
.invalido {
  display: block;
}
/*
td {
  vertical-align: middle !important;
}
*/
.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-leave-to {
  opacity: 0;
}

.select-adjust {
  flex: 1 1 auto;
  margin-bottom: 0;
}

.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
.center-cell {
text-align: center;
}
 
</style>